//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 分期
import { SubOrderDetail, classList, School, Majorlist } from '@/api/ceshi_xiugai/whole';
import { getUnfollow } from '@/api/PublicAPI';
import { getInfo } from '@/api/login';
import { checkCustomerByMobile } from '@/api/updateUserInfo/users';
import { getStudyMajor, getSchoolLevels, getOrderStatus, auditCustomerOrder } from '@/api/ceshi_xiugai/order';
export default {
  components: {},
  data: function data() {
    return {
      calls: '',
      MobileAddress: '',
      dialogShow: false,
      get_type: 0,
      charge_type: '',
      //用户对应组织架构类型
      orderPagesize: 10,
      orderPage: 1,
      info: {},
      types: this.$route.query.types,
      imgPay: [],
      srcList: [],
      ruleForm: {
        desc: ''
      },
      loading: false,
      rules: {
        desc: [{
          required: true,
          message: '请填写驳回原因',
          trigger: 'blur'
        }]
      },
      dialogFormVisible: false
    };
  },
  created: function created() {
    var _this = this;
    getInfo().then(function (res) {
      _this.charge_type = res.data.charge_type;
    });
  },
  mounted: function mounted() {
    var that = this;
    window.addEventListener('storage', function (item) {
      var names = localStorage.getItem('Incoming');
      if (names == 'ringingIb') {
        var call = localStorage.getItem('call');
        checkCustomerByMobile({
          mobile: call
        }).then(function (item) {
          var data = item.data;
          if (data.flag == 1) {
            that.calls = data.cname;
            that.MobileAddress = data.mobile_address;
          } else {
            that.calls = call;
            that.MobileAddress = data.mobile_address;
          }
          that.dialogShow = true;
        });
      }
      if (item.newValue != 'RINGING') {
        that.dialogShow = false;
        localStorage.setItem('Incoming', '');
      }
    });
    this.getoneinfo();
  },
  methods: {
    DialogClick: function DialogClick() {
      this.dialogShow = false;
    },
    getoneinfo: function getoneinfo() {
      var _this2 = this;
      var data = {
        id: this.$route.query.id,
        customer_id: this.$route.query.Cid
      };
      SubOrderDetail(data).then(function (respomse) {
        _this2.info = respomse.data;
        // this.info.pay_amount_detail = JSON.parse(this.info.pay_amount_detail);
        _this2.info.pay_amount_detail.map(function (item) {
          if (item.type == 0) {
            item.name = '无';
          } else if (item.type == 1) {
            item.name = '报名费';
          } else if (item.type == 2) {
            item.name = '定金';
          } else if (item.type == 3) {
            item.name = '学费';
          } else if (item.type == 4) {
            item.name = '资料费';
          } else if (item.type == 5) {
            item.name = '语言培训费';
          } else if (item.type == 6) {
            item.name = '普通留学申请费';
          }
        });
        if (respomse.data.picture_list != '') {
          _this2.imgPay = JSON.parse(respomse.data.picture_list);
          for (var i = 0; i < _this2.imgPay.length; i++) {
            if (_this2.imgPay[i].url) {
              _this2.srcList.push(_this2.imgPay[i].url);
            }
          }
        }
      });
    },
    audit: function audit() {
      var _this3 = this;
      getOrderStatus({
        customer_order_id: this.$route.query.id
      }).then(function (res) {
        if (res.data.audit_status == -1) {
          _this3.$confirm('该订单数据已被取消，请在列表页刷新后再审核', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {}).catch(function () {});
        } else {
          _this3.$confirm('确定【通过】该订单审核吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this3.auditStateFun(1);
          }).catch(function () {});
        }
      });
    },
    reject: function reject() {
      var _this4 = this;
      getOrderStatus({
        customer_order_id: this.$route.query.id
      }).then(function (res) {
        if (res.data.audit_status == -1) {
          _this4.$confirm('该订单数据已被取消，请在列表页刷新后再审核', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {}).catch(function () {});
        } else {
          _this4.dialogFormVisible = true;
        }
      });
    },
    auditStateFun: function auditStateFun(type) {
      var _this5 = this;
      var params = {};
      if (type == 1) {
        params = {
          order_id: this.info.id,
          audit_type: type
        };
      } else {
        params = {
          order_id: this.info.id,
          audit_type: type,
          audit_reason: this.ruleForm.desc
        };
      }
      this.loading = true;
      auditCustomerOrder(params).then(function (res) {
        _this5.loading = false;
        window.opener = null;
        window.open('about:blank', '_top').close();
      });
    }
  }
};